import HunyVerseStyle from '@/assets/scss/hunyverse.scss'

import Vue from 'vue'
import App from './App.vue'

import router from './router'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    vuetify,
    render: (h) => h(App)
}).$mount('.application')
