import Vue from 'vue'
import VueRouter from 'vue-router'
//import Isbot from 'isbot'

import Routes from '@/constants/routes'
import Stores from '@/constants/stores'

import {
    getBrowserAgent,
    isAndroid,
    isiOS
} from '@/modules/browser'

Vue.use(VueRouter)

/*const userAgent = getBrowserAgent()
const userIsBot = Isbot(userAgent)
const userIsAndroid = isAndroid(userAgent)
const userIsiOS = isiOS(userAgent)

//console.log('userIsBot', userIsBot)
//console.log('userIsAndroid', userIsAndroid)
//console.log('userIsiOS', userIsiOS)

function detectBot (to, from, next) {
    if (!userIsBot && userIsAndroid) {
        window.location = Stores.GooglePlay
    }
    else if (!userIsBot && userIsiOS) {
        window.location = Stores.AppleStore
    }
    else {
        next()
    }
}*/

const routes = [
    {
        path: '/',
        name: Routes.Home,
        component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue')
    },
    {
        path: '/confirm',
        name: Routes.Confirm,
        component: () => import(/* webpackChunkName: "about" */ '@/views/Confirm.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
